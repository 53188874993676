import React from 'react'
import styled from 'styled-components'
import CartSummaryRow from './CartSummaryRow'
import CouponsList from 'components/common/coupons/CouponList'
import type { CartItem } from 'shared-types/Cart'

const CartSummaryRowContainer = styled.div`
	padding: 20px;
	margin: 10px;
	box-shadow: var(--defaultShadow1);
	background: var(--clear);
	border-radius: var(--borderRadiusFrame);

	@media (max-width: 768px) {
		padding: 15px;
	}
`

const CouponsListContainer = styled.div`
	padding: 10px;
`

const CartSummaryRows = ({
	cartItems,
	allowUpdate,
	isCheckout,
}: {
	cartItems: Record<string, CartItem[]>
	allowUpdate: boolean
	isCheckout: boolean
}) => (
	<>
		<CouponsListContainer data-testid="coupons-list-container">
			<CouponsList />
		</CouponsListContainer>

		{Object.keys(cartItems).map((_itemId) =>
			cartItems[_itemId].map((_cartItem, cartItemIdx) => {
				const _key = `${_itemId}_${cartItemIdx}`

				return (
					<React.Fragment key={_key}>
						<CartSummaryRowContainer key={`${_key}_div`} data-testid="cart-item-card">
							<CartSummaryRow
								key={_key}
								cartItem={_cartItem}
								cartItemIdx={cartItemIdx}
								allowUpdate={allowUpdate}
								isCheckout={isCheckout}
							/>
						</CartSummaryRowContainer>
					</React.Fragment>
				)
			})
		)}
	</>
)

export default CartSummaryRows
