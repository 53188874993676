// @ts-nocheck
import React, { useEffect, useState, useMemo } from 'react'
import { inject, observer } from 'mobx-react'

import SwitchHeader from './header/SwitchHeader'
import { isMobile, routeToPage } from 'utils/utils'
import { withDialog } from 'HOCs/withDialog'
import AccountStepsManager from './AccountSteps/AccountStepsManager'
import styled, { createGlobalStyle } from 'styled-components/macro'
import SideMenu from './common/sideMenu/SideMenu'
import BackdropAndLoader from './common/BackdropAndLoader'
import SubHeaderGroup from 'components/header/SubHeaderGroup'
import CartSummary from 'components/cart/CartSummary'
import SlideRoute from 'routing/SlideRoute'
import { CONSTANTS, Page } from 'utils/constants'
import { useCurrentUrlInfo } from 'hooks/useCurrentUrlInfo'
import WithGuard from '../HOCs/withGuard'
import ECommerceFooter from 'static/components/ECommerceFooter'
import Popup from '../static/components/StoreLocator/common/Popup'
import TestModeNotification from 'components/TestModeNotification'

import { Guard } from 'mobx/Infra/Infra.type'

import { useStores } from 'hooks/useStores'

const PAGES_WITHOUT_FOOTER = [Page.CHECKOUT, Page.ORDER_CONFIRMATION, Page.ORDER_SUMMARY, Page.MENU, Page.COUPONS]

const GlobalStyle = createGlobalStyle`
  #root {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
  }
`

const AppContent = styled.div`
	margin-top: ${({ $useOffset }) => ($useOffset ? '70px' : '0')};
`

const StickyHeader = styled.header`
	position: sticky;
	top: 0;
	z-index: 1200;
`

const Skeleton = inject(
	'Infra',
	'Application',
	'User'
)(
	observer((props) => {
		const {
			Infra,
			Application,
			User,

			children,
			showHeader,
			isMenuAutoLoad,
			hideNavigation,
			showStoreInfo,
			showMenuTabs,
			page,

			onClosePopup,
			isPopupOpen,
			storeData,
			onStartOrderButtonClick,
			...headerProps
		} = props

		const [deliveryInfo, setDeliveryInfo] = useState('')
		const [showCartSummary, setShowCartSummary] = useState(false)
		const [isUserLocalized, setIsUserLocalized] = useState(false)
		const [isHydrated, setIsHydrated] = useState(false)
		const { pathname } = useCurrentUrlInfo()
		const desktop = !isMobile()
		const headerData = hideNavigation ? null : Infra.eCommerceFooter?.header
		const isPageMenu = page === Page.MENU
		const isCheckout = page === Page.CHECKOUT
		const isSummary = page === Page.ORDER_SUMMARY
		const isStoreLocator = page === Page.STORE_LOCATOR
		const isSelectedStore = page === Page.SELECTED_STORE
		const isHome = page === Page.HOME
		const logos = {
			header: {
				desktop: Infra.appParams?.assets?.logoDesktopHeader,
				mobile: Infra.appParams?.assets?.logoMobileHeader,
			},
			footer: {
				desktop: Infra.appParams?.assets?.logoDesktopFooter,
				mobile: Infra.appParams?.assets?.logoMobileFooter,
			},
		}
		const showAddressBox = useMemo(
			() => Infra?.appParams?.features?.staticPagesWithAddressBox?.includes(page),
			[Infra?.appParams?.features?.staticPagesWithAddressBox, page]
		)
		const { isMobileApp } = Application
		const displayFooter = !Application.isMobileApp && Infra.appParams?.eCommerce && Infra.eCommerceFooter && !PAGES_WITHOUT_FOOTER.includes(page)

		const { addressManagerV2Store } = useStores()

		useEffect(() => {
			setIsHydrated(true)

			setIsUserLocalized(addressManagerV2Store.isUserLocalized())
			if (addressManagerV2Store.isUserLocalized()) {
				const isDelivery = User.getOrderType() === CONSTANTS.DELIVERY_METHODS.DELIVERY
				setDeliveryInfo(addressManagerV2Store.getAddressWhenLocalized(isDelivery))
			}
		}, [])

		useEffect(() => {
			Application.setPage(routeToPage(pathname))
		}, [pathname])

		return (
			<SlideRoute>
				<StickyHeader>
					<SwitchHeader
						{...headerProps}
						isCheckout={isCheckout}
						isHome={isHome}
						$isMobileApp={isMobileApp && !isPageMenu}
						headerData={headerData}
						setShowCartSummary={setShowCartSummary}
						showCartSummary={showCartSummary}
					/>
					{showHeader && (
						<SubHeaderGroup
							page={page}
							showStoreInfo={showStoreInfo}
							showMenuTabs={showMenuTabs}
							deliveryInfo={deliveryInfo}
							isPageMenu={isPageMenu}
							showAddressBox={showAddressBox}
							onStartOrderButtonClick={onStartOrderButtonClick}
							storeData={storeData}
						/>
					)}
				</StickyHeader>

				<WithGuard context={Guard.Context.account} type={Guard.Type.page} name={Guard.Name.account}>
					<AppContent>{children}</AppContent>
				</WithGuard>

				{!isCheckout && isUserLocalized && (
					<CartSummary
						isMenu
						isSummary={isSummary}
						isOpen={showCartSummary}
						setShowCartSummary={setShowCartSummary}
						isHover={!isPageMenu && desktop}
					/>
				)}

				{Infra.loading && (
					<BackdropAndLoader
						$isMenuAutoLoad={isMenuAutoLoad}
						open={Infra.loading}
						chainId={Infra.appParams?.c}
						brand={Infra.appParams.brand}
					/>
				)}
				{Infra.displayMobileMenu && <SideMenu headerProps={headerProps} />}
				<AccountStepsManager />
				<TestModeNotification />
				{displayFooter && <ECommerceFooter data={Infra.eCommerceFooter} logos={logos.footer} languages={Infra.appParams.languages} />}
				{(isStoreLocator || isSelectedStore) && <Popup {...Infra?.popup} open={isPopupOpen} onClose={() => onClosePopup(false)} />}
				<GlobalStyle />
				{/* Is used by automation to identify when hydration is completed and when page is ready for user interactions */}
				{isHydrated && <span data-testid="is-hydrated" style={{ display: 'none' }} />}
			</SlideRoute>
		)
	})
)

export default withDialog(Skeleton)
