// @ts-nocheck
import React from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import TypographyPro from '../../themes/TypographyPro'
import IconComponent from '../../themes/IconComponent'
import { useStores } from 'hooks/useStores'

const SpecialDiscountWarningWrapper = styled.div`
	margin-bottom: 20px;
	background: var(--infoBg);
	color: var(--fonts);
	border-radius: 2px;
	padding: 10px 15px;
	display: flex;
	gap: 10px;
`

const StyledInfoIcon = styled(IconComponent).attrs({
	asset: '/icons/info.svg',
})`
	min-width: 24px;
	height: auto;
`

const SpecialDiscountWarnings = inject('Application')(
	observer((props) => {
		const { Application } = props
		const { cartStore } = useStores()
		if (Application.page !== 'checkout') {
			return null
		}
		return (
			<>
				{cartStore.serverWarningsToDisplay.map(({ text }) => (
					<SpecialDiscountWarningWrapper>
						<StyledInfoIcon />
						<TypographyPro component="div" variant="BodyRegular">
							{text}
						</TypographyPro>
					</SpecialDiscountWarningWrapper>
				))}
			</>
		)
	})
)
export default SpecialDiscountWarnings
