// @ts-nocheck
import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import Drawer from '@material-ui/core/Drawer'
import Backdrop from '@material-ui/core/Backdrop'
import { inject, observer } from 'mobx-react'
import { useMediaQuery } from 'hooks/useMediaQuery'
import { isRTL, isMobileApp } from 'utils/utils'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import styled from 'styled-components'
import TypographyPro from 'themes/TypographyPro'
import { HeaderSection, HeaderSectionLeft, HeaderSectionRight, StyledAppBar, StyledToolbar, StyledDivider } from 'components/header/header-styles'
import ECommerceOnly from 'components/common/ECommerceOnly'
import NotECommerceOnly from 'components/common/NotECommerceOnly'
import SignInHeaderSection from 'components/header/SignInHeaderSection'
import { Search } from '@material-ui/icons'
import HeaderLinks from 'components/header/headers/HeaderLinks'
import { LOGO_TYPE, Logo } from 'components/common/Logo'
import ShoppingCart from 'components/common/ShoppingCart'
import HamburgerMenuButton from 'components/header/HamburgerMenuButton'
import { breakpoints } from 'styles/mixins'
import Link from 'components/common/RouterLink'
import { useRouter } from 'next/router'
import { Page } from 'utils/constants'
import { useStores } from 'hooks/useStores'

const HeaderLogo = styled(Logo)`
	width: 100vw;
	height: 100vh;
	max-width: 235px;
	max-height: 48px;
	@media (max-width: 576px) {
		max-width: 105px;
		max-height: 43px;
	}
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	flex-grow: 1;
	cursor: pointer;
`

const StyledBackLink = styled(ArrowBackIosIcon)`
	color: #686767;
`

const StyledCustomHeader = styled(TypographyPro)`
	color: var(--fonts);
	text-transform: uppercase;
`

export const BrowserHeader = inject(
	'User',
	'Payment',
	'Infra',
	'Application',
	'Home'
)(
	observer(
		({
			User,
			Payment,
			Infra,
			Application,
			Home,
			className,
			isHome,
			setShowCartSummary,
			showCartSummary,
			showBack,
			customGoBack,
			showSideMenu,
			showMenu,
			headerData,
			showLogo,
			customTitle,
			showCustomTitle = false,
			showCart,
			showSearch,
		}) => {
			const { addressManagerV2Store } = useStores()
			const router = useRouter()
			const isMobileView = useMediaQuery({ query: `(max-width: ${breakpoints.sm})` })
			// const minHeight = 48 + (props.showSearch ? 42 : 0)
			const goBack = () => {
				if (typeof customGoBack !== 'undefined') {
					customGoBack()
				} else {
					if (Payment.isFormEditable) {
						Payment.setFormEditable(false)
					}

					return router.back()
				}
			}

			return (
				<>
					<StyledAppBar id="appBarHeader" position="relative">
						<StyledToolbar
							disableGutters
							$disableShadowOnMobileAndEnableOnDesktop={isHome && Infra?.appParams?.features?.useNewAddressBox}
						>
							<HeaderSectionLeft>
								{((Infra.appParams?.eCommerce && isMobileView) || showSideMenu) && (
									<HamburgerMenuButton id={`${Application.page}-page-app-menu`} />
								)}
								{showBack && (
									<IconButton
										id={`${Application.page}-page-return-button`}
										edge="start"
										color="inherit"
										aria-label="back"
										onClick={goBack}
										style={isRTL(User.session.locale) ? { transform: 'rotate(180deg)' } : {}}
									>
										<StyledBackLink />
									</IconButton>
								)}
							</HeaderSectionLeft>

							<HeaderSection>
								{showLogo && (
									<>
										<ECommerceOnly>
											<div style={{ display: 'contents' }}>
												<HeaderLogo className={className} logoType={LOGO_TYPE.HEADER} />
											</div>
										</ECommerceOnly>
										<NotECommerceOnly>
											<HeaderLogo className={className} logoType={LOGO_TYPE.HEADER} />
										</NotECommerceOnly>
									</>
								)}

								<ECommerceOnly>
									{!isMobileView && !showMenu && headerData && (
										<HeaderLinks
											headerData={headerData}
											pageLang={Infra?.appParams?.l}
											isMobileApp={Application.isMobileApp}
											isUserLocalized={addressManagerV2Store?.isUserLocalized()}
											setUserSession={User.setSession}
											onMenuClickWhenLocalized={addressManagerV2Store?.onMenuClickWhenLocalized}
											allStoresClosed={Home.areAllStoresClosed()}
										/>
									)}
									{showCustomTitle && <StyledCustomHeader variant="h5">{customTitle}</StyledCustomHeader>}
								</ECommerceOnly>
							</HeaderSection>

							<HeaderSectionRight $isIphone={isMobileView}>
								{!isMobileApp() && !Infra?.appParams?.features?.disableSignUp && (
									<ECommerceOnly>
										<SignInHeaderSection />
									</ECommerceOnly>
								)}

								{showCart && isMobileView && (
									<Link to={`/summary${window.location.search}`}>
										<ShoppingCart setShowCartSummary={setShowCartSummary} showCartSummary={showCartSummary} />
									</Link>
								)}
								{showCart && !isMobileView && (
									<ShoppingCart setShowCartSummary={setShowCartSummary} showCartSummary={showCartSummary} />
								)}
							</HeaderSectionRight>
						</StyledToolbar>
						{showSearch && <Search />}
						{Application.page !== Page.HOME && <StyledDivider />}
					</StyledAppBar>

					{/* WORKAROUND (suggested by creator of Material) these components are added and hidden to load the
					related MUI styling in the <head> so they are already present for when they are used in the MenuIconComponent */}
					<Drawer style={{ display: 'none' }} />
					<Backdrop style={{ display: 'none' }} open={false} />
				</>
			)
		}
	)
)

export default BrowserHeader

BrowserHeader.defaultProps = {
	showCustomTitle: false,
}
