// @ts-nocheck
import { webFlowAddress } from 'utils/api/webFlowAddress'
import { geoCode } from 'utils/googleMapsUtils/googleMapsUtils'
import lookupCountry from 'country-code-lookup'
import Home from 'mobx/Home'
import Infra from 'mobx/Infra'
import AddressManager from 'mobx/AddressManager'
import type AddressManagerV2Store from 'mobx/AddressManagerV2/store'

// This function is used to handle the result of google places dropdown returning data
export default async (googlePlaceData = {}, orderType: number = null, addressManagerV2Store: AddressManagerV2Store = null) => {
	const { l: lang, c: chainId } = Infra.appParams
	const formatted = googlePlaceData.formatted || googlePlaceData?.formatted_address
	const lat = googlePlaceData.lat || googlePlaceData?.geometry?.location?.lat()
	const lng = googlePlaceData.lng || googlePlaceData?.geometry?.location?.lng()
	const addressManager = addressManagerV2Store || AddressManager
	const addressData = addressManager.addressPopup?.mapButtonClicked ? {} : { formatted, lat, lng }
	const { locale } = Home

	try {
		const res = await webFlowAddress.getBranchesList({ ...addressData, chainId, lang, orderType, showLoader: false })
		if (!res.error) {
			const addressObject = googlePlaceData

			if (addressManager.addressPopup?.mapButtonClicked) {
				const geoCodeRes = await geoCode({
					address: lookupCountry.byIso(locale.region).country,
					componentRestrictions: {
						country: locale.region,
					},
				},
				locale?.key)
				const latLng = geoCodeRes.geometry.location.toJSON()
				Home.setCurrentLocation({ address: '', ...latLng })
			} else {
				Home.setCurrentLocation({ address: formatted, lat, lng })
			}

			Home.setStores(res.msg || [])

			return {
				addressInputValue: '',
				modalOpen: true,
				addressObject,
				shouldGoToFirstStoreFound: !!res?.autoProximitySelection,
			}
		}
	} catch (err) {
		console.error(err)
	}
}
