// @ts-nocheck
import styled, { css } from 'styled-components'
import { AppBar, Divider } from '@material-ui/core'
import Toolbar from '@material-ui/core/Toolbar'
import TypographyPro from '../../themes/TypographyPro'
import { respondTo, respondAbove } from '../../styles/mixins'

export const StyledAppBar = styled(AppBar)`
	&.MuiAppBar-root {
		box-shadow: none;
	}
	&.MuiAppBar-colorPrimary {
		background-color: var(--clear);
	}
	z-index: 1101;
`

export const StyledToolbar = styled(Toolbar)`
	@media (max-width: 576px) {
		height: 45px;
		padding: 5px 14px 5px 19px;
		box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);

		${({ $disableShadowOnMobileAndEnableOnDesktop }) =>
			$disableShadowOnMobileAndEnableOnDesktop &&
			css`
				box-shadow: unset;
			`}
	}
	height: 83px;
	min-height: 48px;
	padding: 10px 40px 10px 39px;
	position: relative;
	${({ $disableShadowOnMobileAndEnableOnDesktop }) =>
		$disableShadowOnMobileAndEnableOnDesktop &&
		css`
			${respondAbove.lg`
			box-shadow: var(--highlightShadow1);
	`}
		`}
`

export const HeaderSectionLeft = styled.div`
	text-align: start;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
`
export const HeaderSection = styled.div`
	display: flex;
	z-index: 999;
`
export const HeaderSectionRight = styled.div`
	flex: 1;
	text-align: end;
	display: ${({ $isIphone }) => !$isIphone && 'flex'};
	align-items: center;
	justify-content: flex-end;

	@media (max-width: 576px) {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
`

export const StyledDivider = styled(Divider)`
	&.MuiDivider-root {
		margin: 0 15px;
	}
`

export const LogoContainer = styled.div`
	display: flex;
	justify-content: center;
`

export const StyledPrimeButtonText = styled(TypographyPro)`
	${({ theme }) => theme.typography.CTABig};
	@media (max-width: 576px) {
		${({ theme }) => theme.typography.CTASmall};
	}
`

export const HeaderLink = styled(TypographyPro)<{ $isHome?: boolean }>`
	cursor: pointer;
	display: flex;
	flex-direction: row;
	color: var(--footerAndDarkBackgrounds);
	${respondTo.sm`
		margin-inline-start: 16px;
	`}
	${({ $isHome }) =>
		$isHome &&
		css`
			text-transform: uppercase;
			margin: 0 15px;
		`}
`

export const StyledHeaderLinksContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-inline-start: 45px;
	margin-top: 7px;
	@media (max-width: 576px) {
		display: none;
	}
`
