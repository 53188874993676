import React from 'react'
import { inject, observer } from 'mobx-react'
import ReCAPTCHA from 'react-google-recaptcha'

import type _Infra from 'mobx/Infra'

const Recaptcha = inject(
	'Account',
	'Infra'
)(
	observer(
		({ Infra, onComplete, onError, onReady }: { Infra: typeof _Infra; onComplete: () => void; onError: () => void; onReady: () => void }) => {
			const onChange = (value: string | null) => {
				if (value) {
					onComplete()
				} else {
					onError()
				}
			}

			return <ReCAPTCHA size="normal" asyncScriptOnLoad={onReady} sitekey={Infra.appParams.features.reCaptcha?.siteKey} onChange={onChange} />
		}
	)
)

export default Recaptcha
