// @ts-nocheck
import React, { useRef, useEffect, useContext } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { z } from 'zod'
import styled from 'styled-components'
import { zodResolver } from '@hookform/resolvers/zod'
import Grid from '@material-ui/core/Grid'
import { inject, observer } from 'mobx-react'
import TypographyPro from 'themes/TypographyPro'
import { respondAbove } from 'styles/mixins'
import Skip from './Skip'
import TextField from 'components/common/TextField'

import { SubmitStyledButton, SubmitButtonContainer, Form, TitleContainer, StyledGridContainer } from './Steps.styles'
import { getTranslatedTextByKey, formatLocalDateTime, getStoreName } from 'utils/utils'
import { sendCustomEvent } from '../../../utils/analytics/analytics'
import { CONSTANTS, ORDER_TYPES } from 'utils/constants'
import { StoreContext } from 'contexts/StoreContext'
import { FeatureFlagEnum } from 'types/constants.types'
import { isValidEmail, validatePhone } from './loginHelper'

import { useStores } from 'hooks/useStores'

const Title = styled(TypographyPro)`
	color: var(--strokeMenuTitle);
`

const Subtitle = styled(TypographyPro)`
	padding-top: 0.75rem;
`

const InputsContainerGrid = styled(Grid)`
	flex-direction: column;
	justify-content: space-between;
	grid-gap: 12px; /* Safari 10-11 */
	gap: 12px;
	display: grid;
	grid-template-columns: repeat(1, 1fr);

	${respondAbove.md`
		display: flex;
		grid-template-columns: unset;
	`}
`

const errorKeysMap = {
	'email.alreadyTaken': 'emailAlreadyTakenError',
	'phone.alreadyTaken': 'phoneAlreadyTakenError',
}

const nameRegex = /^[\p{L} -]+$/u

const ExtendUserDetails = inject(
	'Infra',
	'Account',
	'User',
	'Application',
	'Home'
)(
	observer(({ Account, Application, Infra, User, Home, onSkipClick, login }) => {
		const { storeUserDetails, openSignUpPopUp, userTokens, defaultCountry } = Account
		const textInput = useRef(null)
		const { store } = useContext(StoreContext)
		const { addressManagerV2Store } = useStores()

		const missingEmail = !userTokens?.user?.email

		const formSchema = z.object({
			fullName: z.string().min(2).max(50).regex(nameRegex),
			phoneOrEmail: z
				.string()
				.trim()
				.min(1, { message: `${getTranslatedTextByKey('webviewFlow.cannotBeEmpty', 'Cannot be empty')}` })
				.refine(missingEmail ? isValidEmail : (val) => validatePhone(val, defaultCountry), {
					message: missingEmail
						? getTranslatedTextByKey('webviewFlow.mustBeValidEmail', 'Must be a valid email')
						: getTranslatedTextByKey('webviewFlow.mustBeValidNumber', 'Must be a valid number'),
				}),
		})

		const {
			handleSubmit,
			formState: { errors, isDirty, isValid },
			setError,
			clearErrors,
			control,
		} = useForm({
			resolver: zodResolver(formSchema),
			mode: 'onChange',
			defaultValues: { fullName: '', phoneOrEmail: '' },
		})

		const signInMandatoryEmail = Infra?.hasFeatureFlag(FeatureFlagEnum.SIGN_IN_MANDATORY_EMAIL)
		const labelSuffix = signInMandatoryEmail ? ' *' : ''
		const fullNameLabel = `${getTranslatedTextByKey('eCommerce.signIn.fullName', 'Full name')}${labelSuffix}`
		const emailLabel = `${getTranslatedTextByKey('eCommerce.signIn.email', 'Email address')}${labelSuffix}`
		const phoneLabel = `${getTranslatedTextByKey('eCommerce.signIn.phoneNumber', 'Phone number')}${labelSuffix}`

		useEffect(() => {
			setTimeout(() => {
				textInput.current.focus()
			}, 100)
		}, [])

		const onSubmit = async ({ fullName, phoneOrEmail }) => {
			try {
				Infra.setLoading(true)

				// check SIGN_IN_MANDATORY_EMAIL feature and if enabled, we do login (new login workflow)
				if (signInMandatoryEmail) {
					await login()
				}

				await storeUserDetails(Application.backendChannel, fullName, missingEmail ? phoneOrEmail : null, missingEmail ? null : phoneOrEmail)

				const _localDateTime = formatLocalDateTime(Infra.locale.msg)

				const orderType = User.getOrderType() === CONSTANTS.DELIVERY_METHODS.DELIVERY ? ORDER_TYPES.DELIVERY : ORDER_TYPES.PEAKUP
				// the user has entered their name and email
				// this is not account signup completed since signup can complete by just entering your mobile number
				sendCustomEvent({
					category: 'account',
					action: 'signup',
					label: 'user details added',
					date: _localDateTime,
					uuid: Account.user?.user?.userConsistentId || '',
					phone_number: Account.user?.user?.phone || '',
					loginMethod: Account.getIdentifier()?.type,
					email: Account.user?.user?.email || '',
					first_name: Account.user?.user?.firstName || '',
					last_name: Account.user?.user?.lastName || '',
					storeID: store?.data?.id || '',
					storeName: getStoreName(store, orderType, Home.locale.msg, addressManagerV2Store) || '',
				})

				openSignUpPopUp(false)
			} catch (error) {
				if (error.response?.status === 409) {
					const errorKey = errorKeysMap[error.response?.data?.error?.code]
					setError('phoneOrEmail', {
						message: getTranslatedTextByKey(`webviewFlow.${errorKey}`, error.response?.data?.error?.message),
					})
				} else {
					clearErrors()
				}
			} finally {
				Infra.setLoading(false)
			}
		}

		return (
			<StyledGridContainer>
				<TitleContainer item>
					<Title variant="h3">{getTranslatedTextByKey('eCommerce.signIn.welcome')},</Title>
					<Title variant="h3">{getTranslatedTextByKey('eCommerce.signIn.thankYou')}</Title>
					<Subtitle variant="BodyRegular">{getTranslatedTextByKey('eCommerce.signIn.knowMore')}</Subtitle>
				</TitleContainer>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<InputsContainerGrid container direction="column" data-testid="new-account-name-and-email-inputs">
						<Controller
							name="fullName"
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextField
									onChange={onChange}
									value={value}
									inputRef={textInput}
									autoComplete="off"
									error={Object.prototype.hasOwnProperty.call(errors, 'fullName')}
									label={fullNameLabel}
									inputProps={{ 'data-testid': 'user-name-field' }}
								/>
							)}
						/>
						<Controller
							name="phoneOrEmail"
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextField
									onChange={onChange}
									value={value}
									autoComplete="off"
									error={Object.prototype.hasOwnProperty.call(errors, 'phoneOrEmail')}
									errorMessage={errors.phoneOrEmail?.message}
									label={missingEmail ? emailLabel : phoneLabel}
									inputProps={{
										'data-testid': missingEmail ? 'email-field' : 'phone-field',
									}}
								/>
							)}
						/>
					</InputsContainerGrid>
					<SubmitButtonContainer container direction="row">
						<SubmitStyledButton $size="big" type="submit" disabled={!isDirty || !isValid} data-testid="new-user-name-email-verify-button">
							{getTranslatedTextByKey('eCommerce.signIn.submit')}
						</SubmitStyledButton>
					</SubmitButtonContainer>
				</Form>
				{Account.signupDisplaySkipSection && (
					<Skip
						caption={getTranslatedTextByKey('eCommerce.signIn.skip')}
						onSkip={onSkipClick}
						or={getTranslatedTextByKey('eCommerce.signIn.or')}
					/>
				)}
			</StyledGridContainer>
		)
	})
)

export default ExtendUserDetails
