// @ts-nocheck
import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { inject, observer } from 'mobx-react'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import Select, { components } from 'react-select'
import { codeToLocale, getTranslatedTextByKey } from 'utils/utils'
import TextField from 'components/common/TextField'
import IconComponent from 'themes/IconComponent'
import TypographyPro from 'themes/TypographyPro'
import { uniqBy } from 'lodash'

import type { Page } from 'utils/constants'
import { GOOGLE_API_KEY, GOOGLE_MAPS_AUTOCOMPLETE_OPTIONS, LOCALIZATION_TYPE } from 'utils/constants'
import { FeatureFlagEnum } from 'types/constants.types'
import { sendCustomEvent } from 'utils/analytics/analytics'

const FindIcon = (props) => <IconComponent asset="/icons/find.svg" {...props} />
const TimeIcon = (props) => <IconComponent asset="/icons/timeIcon.svg" fillColor="var(--highlights)" {...props} />

const Container = styled.div`
	display: flex;
	${({ $itemPopupTop }) => `flex-direction: ${$itemPopupTop ? 'row-reverse' : 'row'};`}

	${({ $itemPopupTop }) => `box-shadow: ${$itemPopupTop ? 'none' : 'var(--defaultShadow1)'};`}
    flex: 1;

	fieldset {
		border: 0;
	}

	.MuiInputBase-root,
	.MuiOutlinedInput-input {
		padding: unset;
	}
`

const StyledOption = styled(TypographyPro)`
	color: var(--fonts);
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	line-clamp: 1;
	-webkit-box-orient: vertical;

	${({ $lastAddress }) => $lastAddress && `color: var(--inactive);`}
`

const StyledTimeIcon = styled(TimeIcon)`
	height: 18px;
	width: 18px;
`

const StyledFindIcon = styled(FindIcon)`
	height: 24px;
	width: 24px;
`

const StyledHistoryText = styled(TypographyPro)`
	color: var(--strokeMenuTitle);
`

const pulseAnimation = keyframes`
    0% {
        transform: scale(1);
        box-shadow: 0 2px 2px rgba(0, 0, 0, .2);
        border-radius: unset;
    }
    100% {
        transform: scale(1.1);
        box-shadow: 0 2px 2px rgba(0, 0, 0, .2);
    }
`

const FindMyLocationIconContainer = styled.div`
	min-width: 55px;
	background-color: var(--primary);
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border-top-right-radius: var(--borderRadiusInput);
	border-bottom-right-radius: var(--borderRadiusInput);
	${({ $itemPopupTop }) =>
		$itemPopupTop &&
		`border-top-left-radius: var(--borderRadiusInput);
		border-bottom-left-radius: var(--borderRadiusInput);`}
	box-shadow: var(--highlightShadow2);
	animation: ${({ $highlightUseMyLocationBtn }) =>
		$highlightUseMyLocationBtn
			? css`
					${pulseAnimation} 0.5s infinite alternate ease-in-out forwards;
			  `
			: 'none'};
	z-index: 1;
	${({ $itemPopupTop }) => `margin-right: ${$itemPopupTop ? '8px' : '0'};`}
`

const EndAdornmentAddressSearch = ({ onClick, highlightUseMyLocationBtn, itemPopupTop, page }) => (
	<FindMyLocationIconContainer
		id={`${page}-page-localization-icon`}
		data-testid="use-user-location-button"
		role="button"
		onClick={onClick}
		$highlightUseMyLocationBtn={highlightUseMyLocationBtn}
		$itemPopupTop={itemPopupTop}
	>
		<IconComponent asset="/icons/useMyLocation.svg" />
	</FindMyLocationIconContainer>
)

const ValueContainer = ({ children, ...props }) =>
	components.ValueContainer && (
		<components.ValueContainer {...props}>
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>
				<StyledFindIcon />
				{children}
			</div>
		</components.ValueContainer>
	)

const Input = (props: {
	page: Page
	onFocus: React.FocusEventHandler
	onBlur: React.FocusEventHandler
	onChange: React.ChangeEventHandler
	value: string | null
	setValue: React.Dispatch<React.SetStateAction<string | null>>
	innerRef: React.RefObject<HTMLInputElement>
}) => (
	<TextField
		autoComplete="off"
		placeholder={getTranslatedTextByKey('webviewFlow.localization.onTheMap.textFieldPlaceholder', 'Enter your location')}
		id={`${props.selectProps.page}-page-type-location`}
		inputProps={{
			'data-testid': 'user-address-input',
			onFocus: props.onFocus,
			onBlur: props.onBlur,
		}}
		ref={props.innerRef}
		value={props.value}
		onChange={props.onChange}
		onResetValue={() => props.setValue(null)}
		endAdornmentUseOnTouch
	/>
)

const Option = (props) => (
	<components.Option {...props}>
		{props.data.lastAddress && <StyledTimeIcon />}
		{props.data.lastAddress && (
			<StyledHistoryText variant="h5" component="span">
				{getTranslatedTextByKey('webviewFlow.history', 'HISTORY')}
			</StyledHistoryText>
		)}
		<StyledOption variant="BodySmall" component="span" $lastAddress={props.data.lastAddress} data-testid="option">
			{props.label}
		</StyledOption>
	</components.Option>
)

const selectStylesContainer = (itemPopupTop) => ({
	container: (base) => ({
		...base,
		width: '100%',
		border: itemPopupTop ? '1px solid var( --inactive)' : 'unset',
		borderRadius: itemPopupTop ? 'var(--borderRadiusFrame)' : 'unset',
	}),
})

const selectStylesControl = (itemPopupTop) => ({
	control: (base) => ({
		...base,
		border: 'unset',
		boxShadow: 'none',
		borderRadius: itemPopupTop ? 'var(--borderRadiusFrame)' : '0',
		borderTopLeftRadius: 'var(--borderRadiusInput)',
		borderBottomLeftRadius: 'var(--borderRadiusInput)',
	}),
})

const selectStylesMenu = (itemPopupTop) => ({
	menu: (base) => ({
		...base,
		borderRadius: 4,
		border: '1px solid rgba(0, 0, 0, 0.15)',
		background: 'var(--clear)',
		boxShadow: 'var(--defaultShadow1)',
		marginTop: 2,
		width: itemPopupTop ? '100%' : 'calc(100% + 55px)',
	}),
})

const selectStyles = {
	container: (base) => ({
		...base,
		width: '100%',
	}),
	control: (base) => ({
		...base,
		border: 'unset',
		boxShadow: 'none',
		borderRadius: 0,
		borderTopLeftRadius: 'var(--borderRadiusInput)',
		borderBottomLeftRadius: 'var(--borderRadiusInput)',
	}),
	input: (base) => ({
		...base,
		color: 'var(--fonts)',
	}),
	placeholder: (base) => ({
		...base,
		color: 'var(--disable)',
		fontFamily: 'BodySmall',
	}),
	menuList: (base) => ({
		...base,
		padding: 'unset',
	}),
	option: (base, state) => ({
		...base,
		cursor: 'pointer',
		border: state.data.lastAddress && '1px solid var(--highlights)',
		display: 'flex',
		flexDirection: 'row',
		backgroundColor: state.isFocused ? 'var(--optionalBackground)' : 'transparent',
		alignItems: 'center',
		gap: 5,
		padding: '7px 10px',
		'&:hover': {
			backgroundColor: state.isFocused && 'var(--optionalBackground)',
		},
	}),
	valueContainer: (base) => ({
		...base,
		padding: 'unset',
		paddingLeft: 10,
		paddingRight: 10,
	}),
}

const GooglePlacesAutoComplete = inject(
	'User',
	'Home',
	'Infra',
	'Application'
)(
	observer(({ Infra, Home, User, Application, onPlaceSelected, getLocation, lastAddressLocation, highlightUseMyLocationBtn, backdropEnabled }) => {
		const [searchVal, setSearchVal] = useState('')
		const typedInputOnce = useRef(false)
		const { locale: localeData = {} } = Infra
		const _key = localeData?.key?.trim() || GOOGLE_API_KEY
		const locale = codeToLocale[User.preferredLanguage]
		const lang = locale?.split('_')[0]
		const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
			apiKey: `${_key}&loading=async`,
			debounce: 300,
			options: {
				types: GOOGLE_MAPS_AUTOCOMPLETE_OPTIONS,
				componentRestrictions: { country: Home.locale.region || '' },
			},
			language: lang,
		})

		const itemPopupTop = Infra?.hasFeatureFlag(FeatureFlagEnum.ADDRESS_BOX_POPUP_TOP)
		const selectRef = useRef(null)

		const options = useMemo(() => {
			const predictionsMapped = placePredictions.map((place) => ({
				label: place.description,
				value: place.place_id,
				lastAddress: false,
			}))

			if (Object.keys(lastAddressLocation)?.length !== 0) {
				return uniqBy([lastAddressLocation, ...predictionsMapped], 'label')
			}

			return predictionsMapped
		}, [placePredictions, lastAddressLocation])

		const loadOptions = async (inputText) => {
			if (inputText && !typedInputOnce.current) {
				typedInputOnce.current = true
				sendCustomEvent({
					category: 'address box',
					action: 'typed',
					page_location: window.location.href?.split('?')[0],
				})
			}
			setSearchVal(inputText)
			await getPlacePredictions({ input: inputText })
		}

		const onSelect = (selectedOption) => {
			if (selectedOption === null) {
				return
			}

			setSearchVal(selectedOption.label)
			if (selectedOption.lastAddress) {
				onPlaceSelected(selectedOption)
				return
			}
			placesService?.getDetails(
				{
					placeId: selectedOption.value,
				},
				(placeDetails) => {
					onPlaceSelected(placeDetails, LOCALIZATION_TYPE.FROM_SELECT)
				}
			)
		}

		useEffect(() => {
			if (selectRef?.current && (highlightUseMyLocationBtn || backdropEnabled)) {
				selectRef.current.focus()
			}
		}, [highlightUseMyLocationBtn, backdropEnabled])

		return (
			<Container $itemPopupTop={itemPopupTop}>
				<Select
					onInputChange={(searchedText) => {
						loadOptions(searchedText)
					}}
					onChange={onSelect}
					styles={{
						...selectStyles,
						...selectStylesContainer(itemPopupTop),
						...selectStylesMenu(itemPopupTop),
						...selectStylesControl(itemPopupTop),
					}}
					placeholder={getTranslatedTextByKey('webviewFlow.localization.onTheMap.textFieldPlaceholder', 'Enter your location')}
					components={{
						SingleValue: () => null,
						IndicatorSeparator: () => null,
						Placeholder: () => null,
						IndicatorsContainer: () => null,
						Option,
						ValueContainer,
						Input,
					}}
					isMulti={false}
					ref={selectRef}
					isLoading={isPlacePredictionsLoading}
					options={options}
					inputValue={searchVal}
					filterOption={() => true}
					cacheOptions
					noOptionsMessage={() => null}
					page={Application.page}
				/>
				<EndAdornmentAddressSearch
					page={Application.page}
					onClick={getLocation}
					highlightUseMyLocationBtn={highlightUseMyLocationBtn}
					itemPopupTop={itemPopupTop}
				/>
			</Container>
		)
	})
)

export default GooglePlacesAutoComplete
