import React, { useContext } from 'react'
import { HeaderLink, StyledHeaderLinksContainer } from '../header-styles'
import { StoreContext } from 'contexts/StoreContext'
import SmartLink from 'static/components/SmartLink'
import { inject, observer } from 'mobx-react'
import type { Page } from 'utils/constants'
import type { Menu } from 'types/Menu'
import { routeToPage } from 'utils/utils'
import { useRouter } from 'next/router'
import type CartStore from 'mobx/Cart'
import { useStores } from 'hooks/useStores'
import type _User from 'mobx/User'

const HeaderLinks = ({
	Application,
	User,
	headerData,
	isMobileApp,
	isUserLocalized,
	setUserSession,
	onMenuClickWhenLocalized,
	allStoresClosed,
}: {
	Application: { page: Page }
	User: typeof _User
	headerData?: { sections?: { path: string; itemType: string; sectionId: string; text: string }[] }
	isMobileApp?: boolean
	isUserLocalized?: boolean
	allStoresClosed?: boolean
	setUserSession: (serverSession: any) => Promise<void>
	onMenuClickWhenLocalized: (
		cartStore: CartStore,
		User: typeof _User
	) => Promise<{ menuRes: Menu; storeMetaData: any; serverSession: any; menuUrl: string }>
}) => {
	const router = useRouter()
	const { setStore } = useContext(StoreContext)
	const isGeneratedStatically = !isMobileApp
	const { cartStore } = useStores()

	return (
		<StyledHeaderLinksContainer>
			{headerData?.sections?.map((headerLink) => {
				const onMenuClick = async () => {
					const { menuRes, storeMetaData, serverSession, menuUrl } = await onMenuClickWhenLocalized(cartStore, User)
					await setUserSession(serverSession)

					setStore((store) => ({ ...store, data: menuRes, metaData: storeMetaData }))

					router.push(`${menuUrl}`)
				}

				if (!allStoresClosed && isUserLocalized && headerLink.path.includes('menu')) {
					return (
						<HeaderLink
							id={`${Application.page}-page-${routeToPage(headerLink.path)}-link`}
							component="a"
							variant="BodySmall"
							key={headerLink.sectionId}
							onClick={onMenuClick}
							data-testid={`${headerLink.path?.slice(headerLink.path.lastIndexOf('/') + 1)}-page-link`}
						>
							<HeaderLink component="div" variant="h4" $isHome>
								{headerLink.text}
							</HeaderLink>
						</HeaderLink>
					)
				}

				return (
					<SmartLink
						id={`${Application.page}-page-${routeToPage(headerLink.path)}-link`}
						sectionItem={headerLink}
						key={headerLink.sectionId}
						isGeneratedStatically={isGeneratedStatically}
						openInSameBrowserTab={headerLink.itemType !== 'external'}
						testId={`${headerLink.path?.slice(headerLink.path.lastIndexOf('/') + 1)}-page-link`}
					>
						<HeaderLink component="div" variant="h4" $isHome>
							{headerLink.text}
						</HeaderLink>
					</SmartLink>
				)
			})}
		</StyledHeaderLinksContainer>
	)
}

export default inject('Application', 'User')(observer(HeaderLinks))
