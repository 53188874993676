import React, { useState, useContext, useEffect, useRef } from 'react'
import { inject, observer } from 'mobx-react'
import { useMediaQuery } from 'hooks/useMediaQuery'
import styled, { css } from 'styled-components/macro'
import { formatPrice, isIPhoneX, injectVarsToTemplateString, getTranslatedTextByKey, checkRequest, getDomainByEnv, isMobileApp } from 'utils/utils'
import CouponCode from '../menu/CouponCode'
import { StoreContext } from 'contexts/StoreContext'
import CostSummaryForChat from './CostSummaryForChat'
import CartSummaryRows from './CartSummaryRows'
import { breakpoints, respondAbove } from 'styles/mixins'
import { CONSTANTS, ORDER_TYPES } from 'utils/constants'
import TypographyPro from '../../themes/TypographyPro'
import useSnackbar from 'hooks/useSnackbar'
import CouponCodeOnNextStep from 'components/menu/couponCodeOnNextStep/CouponCodeOnNextStep'
import TaxDisclaimer from '../common/TaxDisclaimer'
import IconComponent from 'themes/IconComponent'
import loadMenu from 'utils/api/loadMenu/loadMenu'
import { CheckoutButton } from 'components/common/CheckoutButton'
import { useStores } from 'hooks/useStores'
import type _User from 'mobx/User'
import type _MobileApplication from 'mobx/MobileApplication'
import type _Infra from 'mobx/Infra'
import { useRouter } from 'next/router'
import type { Menu } from 'types/Menu'

const StyledMinimumOrderInfo = styled(TypographyPro)`
	line-height: 16px;
`
/**
 * This component have 2 main cases to show up:
 * 1. Menu page on WEB session as a sidebar.
 * 2. Summary page on Mobile session as a main component.
 * Just because of that we are using the isMobile flag to separate these 2 cases.
 * */

interface SummaryCssProps {
	$isMobile: boolean
	$mobileApp: boolean
	$isHover: boolean
}

const Summary = styled.div<SummaryCssProps>`
	position: fixed;
	// We add 20 pixels in order to make sure the text and the Checkout button blur don't overlap.
	// This is hard-coded and bad, but a better solution requires an overhaul of the Cart page.
	bottom: ${({ $mobileApp }) => ($mobileApp ? 125 : 65) + 20}px;
	margin-top: 16px;
	width: ${({ $isMobile, $isHover }) => ($isMobile || $isHover ? 100 : 25)}%;
	padding: 10px 10px 5px;
	border-top: 1px solid var(--disable);
	background-color: var(--clear);

	@media (min-width: 1024px) {
		padding: 15px 10px;
		bottom: 70px;
		width: ${({ $isHover }) => ($isHover ? 100 : 25)}%;
		padding-block-start: 15px;
		border-bottom: 1px solid var(--disable);
	}
`

interface CartSummaryRowsContainerCssProps {
	$isCheckout: boolean
	$isMobile: boolean
	$height: number
}

const CartSummaryRowsContainer = styled.div<CartSummaryRowsContainerCssProps>`
	${({ $isCheckout, $height }) =>
		!$isCheckout &&
		css`
			overflow-y: auto;
			height: calc(${$height}px - 15px);
		`};

	${respondAbove.md`
		padding-bottom: 10px;
	`}

	${isIPhoneX() && 'flex: 1'};
	${({ $isCheckout, $isMobile, $height }) =>
		!$isCheckout &&
		!$isMobile &&
		css`
			padding-bottom: unset;
			overflow-y: auto;
			height: ${$isMobile ? 450 : $height}px;
		`};
`

interface CartContainerCssProps {
	$isCheckout: boolean
}

const CartContainer = styled.div<CartContainerCssProps>`
	${({ $isCheckout }) =>
		!$isCheckout &&
		css`
			display: flex;
			flex-direction: column;

			@media (min-width: 1024px) {
				min-height: 82vh;
			}
		`}
`

const CostSumForChatContainer = styled.div`
	@media (min-width: 1024px) {
		padding: 0;
	}
`

const SummaryTitle = styled(TypographyPro)`
	text-align: center;
	padding: 21.5px 0;
	color: var(--strokeMenuTitle);
`

const EmptyCart = styled.div`
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	padding: 0 21px;
`

interface ContainerCssProps {
	$isCheckout: boolean
	$isMobile: boolean
	$isHover: boolean
	$isOpen: boolean
}

const Container = styled.div<ContainerCssProps>`
	${({ $isCheckout, $isMobile }) =>
		!$isCheckout &&
		($isMobile
			? css`
					width: 100%;
					height: auto;
			  `
			: css`
					position: fixed;
					border-left: 1px solid var(--disable);
					z-index: 1102;
					width: 25%;
					height: 100%;
					right: 0;
					bottom: 0;
			  `)}
	background-color: var(--clear);

	${({ $isHover, $isOpen }) =>
		$isHover &&
		css`
			bottom: 0;
			z-index: 1200;
			animation: ${!$isOpen ? 'cart-disappears 0.3s forwards' : 'cart-appears 0.3s forwards'};
			transition-timing-function: ease-out;
			right: -100%;
		`} @keyframes cart-disappears {
		0% {
			right: 0;
			transform: scale(1) rotateY(0deg);
		}

		100% {
			right: -100%;
			transform: scale(0.5) rotateY(45deg);
		}
	}

	@keyframes cart-appears {
		0% {
			right: -100%;
			transform: scale(0.7) rotateY(0deg);
		}
		100% {
			right: 0;
			transform: scale(1) rotateY(0deg);
		}
	}
`

interface MenuWrapperCssProps {
	$isClosing: boolean
}

const MenuWrapper = styled.div<MenuWrapperCssProps>`
	background-color: #000c;
	position: fixed;
	top: 0;
	left: 0;
	opacity: 0;
	${({ $isClosing }) =>
		$isClosing
			? css`
					animation: fade-out 0.3s forwards;
			  `
			: css`
					width: 100vw;
					height: 100vh;
					animation: fade-in 0.2s forwards;
			  `};
	z-index: 1200;
`

const CloseIcon = styled(IconComponent)`
	position: absolute;
	top: calc(50% - 15px);
	left: -39px;
	transform: translateX(-50%);
	border-radius: 50%;
	width: 30px;
	height: 30px;
	padding: 7px;
	cursor: pointer;
	border: solid 1px var(--clear);
`

interface CartSummaryProps {
	isCheckout: boolean
	isHover: boolean
	isOpen: boolean
	setShowCartSummary: (isOpen: boolean) => void
	className?: string
	isSummary?: boolean
	isMenu?: boolean
	User: typeof _User
	Infra: typeof _Infra
	MobileApplication: typeof _MobileApplication
}

const CartSummary = inject(
	'Infra',
	'Application',
	'User',
	'MobileApplication'
)(
	observer((props: CartSummaryProps) => {
		const router = useRouter()
		const { store, setStore } = useContext(StoreContext)
		const rest: Menu | null = store.data ?? null
		const { User, isCheckout, Infra, isOpen, isHover, setShowCartSummary, className, MobileApplication } = props
		const [containerHeight, setContainerHeight] = useState(0)
		const [windowHeight, setWindowHeight] = useState(0)
		const summaryRef = useRef<HTMLDivElement | null>(null)
		useSnackbar(summaryRef, 'cart')
		const isChatApp = User.isChatApp()
		const integrationTaxEnabled = User.session.isIntegrationTaxEnabled
		const currentPageIsSummary = props.isSummary
		const isMobileView = useMediaQuery({ query: `(max-width: ${breakpoints.sm})` })
		const shouldShowOnlyCheckOutButton = isMobileView && !currentPageIsSummary && !isCheckout
		const { cartStore } = useStores()
		const showCheckoutButton = cartStore.numberOfItems !== 0 || currentPageIsSummary || isCheckout || !isMobileView

		useEffect(() => {
			// useEffect is needed because some type of coupons gets rendered before useEffect get called
			setTimeout(() => {
				if (summaryRef?.current?.offsetHeight) {
					const summaryHeight = summaryRef.current.offsetHeight
					const mobileHeight = windowHeight - summaryHeight - 68 - 120
					const desktopHeight = windowHeight - summaryHeight - 100 - 40
					setContainerHeight(isMobileView ? mobileHeight : desktopHeight)
				}
			}, 300)
		}, [cartStore.charges.length, windowHeight, cartStore.items, isMobileView])

		const reportWindowSize = () => {
			setWindowHeight(window.innerHeight)
		}

		useEffect(() => {
			// when initialize - deduct from the window height the summary (and the button) and the header
			setContainerHeight(window.innerHeight - 106 - 200)
			setWindowHeight(window.innerHeight)

			window.addEventListener('resize', reportWindowSize)

			return function cleanup() {
				window.removeEventListener('resize', reportWindowSize)
			}
		}, [])

		const deliveryType = User.getOrderType() === CONSTANTS.DELIVERY_METHODS.DELIVERY ? ORDER_TYPES.DELIVERY : 'takeout'
		const defaultMinOrderPrice = cartStore.deliveryInfo?.minOrderPrice ?? store.metaData?.orderDeliveryInfo?.minOrderPrice
		const defaultDeliveryTime = store.data?.deliveryInfo?.filter((info) => info.type === deliveryType)[0]?.delayMins ?? 0

		const renderCouponArea = () => {
			if (User.session.hideCouponFieldOnMenuPage) {
				return <CouponCodeOnNextStep />
			}
			if (!isCheckout) {
				return <CouponCode isMenu={props.isMenu} isSummary={props.isSummary} />
			}
		}

		useEffect(() => {
			const init = async () => {
				// const oldCartFromStorage = storage.getStorage()
				const storeId = localStorage.getItem('storeId')
				if (storeId) {
					try {
						const storeIdS = process.env.NODE_ENV === 'production' || Infra?.appParams?.useProductionMenu ? storeId : `${storeId}_dev`
						const serverSession = await checkRequest({
							wru: getDomainByEnv(),
							request: MobileApplication.getSessionId(),
							cust: 'openRest',
							j: storeIdS,
						})
						await User.setSession(serverSession)
					} catch (error) {
						console.error(error)
						return
					}
					const menu = await loadMenu({
						chainId: Infra.appParams.c || Infra.appParams.pc,
						storeId,
						orderTypeFromQS: User.orderType === CONSTANTS.DELIVERY_METHODS.DELIVERY ? ORDER_TYPES.DELIVERY : ORDER_TYPES.PEAKUP,
						appId: User.session.appid,
						stopLoading: true,
					})
					setStore(() => ({ ...store, data: menu, items: menu?.items }))
				}
			}
			const chainId = Infra.appParams.c || Infra.appParams.pc

			if ((isHover || isMobileView) && cartStore.numberOfItems === 0 && chainId) {
				init()
			}
		}, [])

		return (
			<>
				{isHover && !shouldShowOnlyCheckOutButton && <MenuWrapper $isClosing={!isOpen} />}
				<Container
					$isCheckout={isCheckout}
					$isMobile={!!isMobileView}
					$isOpen={isOpen}
					$isHover={isHover}
					className={className}
					data-testid="cart-container"
				>
					{isHover && !shouldShowOnlyCheckOutButton && (
						<CloseIcon asset="/icons/closeWhite.svg" onClick={() => setShowCartSummary(!isOpen)} />
					)}
					{!shouldShowOnlyCheckOutButton && !isCheckout && (
						<SummaryTitle variant="h2" data-testid="cart-summary-title">
							{getTranslatedTextByKey('orderSummary')}
						</SummaryTitle>
					)}

					{cartStore.numberOfItems === 0 && !shouldShowOnlyCheckOutButton && <EmptyCart>{getTranslatedTextByKey('emptyCart')}</EmptyCart>}
					<CartContainer $isCheckout={isCheckout}>
						{!shouldShowOnlyCheckOutButton && (
							<CartSummaryRowsContainer
								$isCheckout={isCheckout}
								$isMobile={!!isMobileView}
								$height={containerHeight}
								data-testid="cart-summary-rows-container"
							>
								<CartSummaryRows
									// the prop key bellow allow the component to re-render if the we navigate on different URL but on the same nextJS page
									// ex:  from  /menu?wru...  to   /menu?wru.....#itemId=xxxx
									key={router.asPath}
									cartItems={cartStore.items}
									isCheckout={isCheckout}
									allowUpdate
								/>
							</CartSummaryRowsContainer>
						)}
						{!isCheckout && (
							<>
								{!shouldShowOnlyCheckOutButton && (
									<Summary ref={summaryRef} $isMobile={!!isMobileView} $mobileApp={isMobileApp()} $isHover={isHover}>
										{renderCouponArea()}

										<CostSumForChatContainer data-testid="cost-sum-container">
											<CostSummaryForChat
												currency={rest?.currency ?? 'USD'}
												countryCode={rest?.countryCode ?? 'us'}
												totalToPayText={getTranslatedTextByKey('expressCheckout.total')}
												locale={rest?.locale ?? 'en_US'}
												showTotal={isCheckout}
												isChatApp={isChatApp}
												serverAddedItemsFromDiscounts={cartStore.addedItemsFromDiscounts}
												serverCharges={cartStore.charges}
												serverGrandTotal={cartStore.grandTotal}
												numberOfItems={cartStore.numberOfItems}
											/>
										</CostSumForChatContainer>
										{store.metaData?.features?.CART_DELIVERY_STATIC_MIN_ORDER_TITLE?.enabled && (
											<StyledMinimumOrderInfo variant="BodySmall">
												{getTranslatedTextByKey(store.metaData.features.CART_DELIVERY_STATIC_MIN_ORDER_TITLE.config?.textKey)}
											</StyledMinimumOrderInfo>
										)}
										{defaultMinOrderPrice > 0 && !store.metaData?.features?.CART_DELIVERY_STATIC_MIN_ORDER_TITLE?.enabled && (
											<StyledMinimumOrderInfo variant="BodySmall">
												{injectVarsToTemplateString(getTranslatedTextByKey('webviewFlow.minimumSpent'), {
													orderType:
														deliveryType === ORDER_TYPES.DELIVERY
															? getTranslatedTextByKey(ORDER_TYPES.DELIVERY)
															: getTranslatedTextByKey(ORDER_TYPES.PEAKUP),
													amountSpent: '',
												})}
												<span> {formatPrice(defaultMinOrderPrice, store.data?.currency, store.data?.countryCode)}</span>
											</StyledMinimumOrderInfo>
										)}
										{defaultDeliveryTime > 0 && (
											<StyledMinimumOrderInfo variant="BodySmall">
												{injectVarsToTemplateString(getTranslatedTextByKey('webviewFlow.estimatedTimeToOrder'), {
													orderType:
														deliveryType === ORDER_TYPES.DELIVERY
															? getTranslatedTextByKey(ORDER_TYPES.DELIVERY)
															: getTranslatedTextByKey(ORDER_TYPES.PEAKUP),
													estimatedTimeNumber: defaultDeliveryTime,
												})}
											</StyledMinimumOrderInfo>
										)}
										{integrationTaxEnabled && <TaxDisclaimer />}
									</Summary>
								)}
								{showCheckoutButton && <CheckoutButton disabled={cartStore.numberOfItems === 0} fullWidth={isHover} />}
							</>
						)}
					</CartContainer>
				</Container>
			</>
		)
	})
)

export default CartSummary
