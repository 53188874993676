// @ts-nocheck
import React, { useContext, forwardRef } from 'react'
import GoogleMapReact from 'google-map-react'
import { StoreContext } from '../../../contexts/StoreContext'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'
import { formatPrice, convertMinutes, getTranslatedTextByKey } from '../../../utils/utils'
import ClearIcon from '@material-ui/icons/Clear'
import DeliveryAreaContent from './DeliveryAreaContent'
import { CONSTANTS } from '../../../utils/constants'

const areaColors = ['#00FF00', '#0000FF', '#FF8000', '#00FF7C', '#F0FF00', '#00FFFF', '#FF00F0', '#00AEFF', '#000A9D', '#FF0000']

const StyledTitle = styled(Typography)`
	font-weight: bold;
	font-size: 40px;
	text-align: center;
`

const StyledMap = styled.div`
	height: ${({ isMobile }) => (isMobile ? '60vh' : 'auto')};
	width: 100%;
	padding-top: ${({ isMobile }) => (isMobile ? '0' : '10px')};
`

const StyledClearIcon = styled(ClearIcon)`
	align-self: flex-end;
`

const StyledMapContent = styled.div`
	width: ${({ isMobile }) => (isMobile ? '100%' : '50%')};
	background-color: ${({ isMobile }) => (isMobile ? '' : 'white')};
	padding: ${({ isMobile }) => (isMobile ? '' : '10px 10px 10px 10px')};
`

const DeliveryAreas = forwardRef(({ isMobile, title, onCloseMap, deliveryInfo, apiKey }, ref) => {
	const { store } = useContext(StoreContext)
	const rest = store.data
	const storeLocale = rest.locale
	const storeLocationCoords = rest.address.latLng
	const [infoWithoutArea] = deliveryInfo ?? []
	const pickupDeliveryTime =
		typeof ((deliveryInfo ?? [])[0] ?? {}).delayMins === 'number'
			? convertMinutes(infoWithoutArea?.delayMins)
			: ((deliveryInfo ?? [])[0] ?? {}).delayMins
	const minutesLang = getTranslatedTextByKey('minutes', 'minutes')
	const hoursLang = getTranslatedTextByKey('hours', 'hours')
	const handleApiLoaded = (map, maps) => {
		const deliveryAreas = deliveryInfo.filter((item, index) => index !== 0)
		deliveryAreas.map((deliveryArea, colorIndex) => {
			const storeArea = new maps.Polygon({
				paths: deliveryArea.area.polygon,
				strokeColor: areaColors[colorIndex % 10],
				strokeOpacity: 0.8,
				strokeWeight: 2,
				fillColor: areaColors[colorIndex % 10],
				fillOpacity: 0.35,
			})
			return storeArea.setMap(map)
		})
		const marker = new maps.Marker({
			position: storeLocationCoords,
			map,
		})
	}
	return (
		<StyledMapContent isMobile={isMobile}>
			{!isMobile && <StyledClearIcon onClick={onCloseMap} />}
			<StyledTitle>{title}</StyledTitle>
			{deliveryInfo
				.filter((item, index) => index !== 0 && item?.type !== 'takeout')
				.map((info, colorIndex) => {
					const timeInfo = typeof info.delayMins === 'number' ? convertMinutes(info.delayMins) : info.delayMins
					const areaTitle = info.area?.title[storeLocale]
					const deliveryTime =
						typeof info.delayMins === 'number'
							? timeInfo.hours && timeInfo.minutes
								? `${timeInfo.hours} ${hoursLang} ${timeInfo.minutes} ${minutesLang}`
								: timeInfo.hours
								? `${timeInfo.hours} ${hoursLang}`
								: `${timeInfo.minutes} ${minutesLang}`
							: info.delayMins
					const deliveryFee = typeof info.charge === 'number' ? formatPrice(info.charge, rest.currency, rest.countryCode) : info.charge
					const minOrder =
						typeof info.delayMins === 'number' ? formatPrice(info.minOrderPrice, rest.currency, rest.countryCode) : info.minOrderPrice
					return (
						<DeliveryAreaContent
							key={areaTitle}
							areaTitle={areaTitle}
							isMobile={isMobile}
							deliveryFee={deliveryFee}
							minOrder={minOrder}
							deliveryTime={typeof info.delayMins === 'number' ? deliveryTime : info.delayMins}
							color={areaColors[colorIndex % 10]}
						/>
					)
				})}
			<StyledMap isMobile={isMobile} data-testid="map">
				<GoogleMapReact
					options={{
						clickableIcons: false,
						fullscreenControl: false,
					}}
					bootstrapURLKeys={{ key: apiKey, libraries: ['places'] }}
					defaultCenter={storeLocationCoords}
					defaultZoom={CONSTANTS.DEFAULT_MAP_ZOOM}
					fullScreenControl={false}
					yesIWantToUseGoogleMapApiInternals
					onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
				/>
			</StyledMap>
		</StyledMapContent>
	)
})

export default DeliveryAreas
