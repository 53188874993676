import type { ReactNode } from 'react'
import React from 'react'
import styled from 'styled-components'
import DialogBase from '@material-ui/core/Dialog'
import DialogTitleBase from '@material-ui/core/DialogTitle'
import DialogContentBase from '@material-ui/core/DialogContent'
import DialogActionsBase from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { respondAbove } from 'styles/mixins'
import Button, { type ButtonType } from 'components/common/ButtonBase'

const Dialog = styled(DialogBase)<{ PaperProps: { 'data-testid': string } }>`
	& .MuiPaper-root {
		width: calc(100% - 40px);
		padding: 20px 20px 30px;
		margin: 0;
		border-radius: var(--borderRadiusFrame);
		display: flex;
		flex-flow: column nowrap;
		align-items: stretch;
		text-align: center;

		${respondAbove.sm`
			width: 550px;
			padding: 20px;
		`}
	}
`

const DialogTitle = styled(DialogTitleBase)`
	${({ theme }) => theme.typography.h4};
	color: var(--strokeMenuTitle);
	padding: 0;
	margin: 20px auto 15px;
`

const DialogContent = styled(DialogContentBase)`
	${({ theme }) => theme.typography.BodyRegular};
	color: var(--fonts);
	padding: 0;
	margin: 0;
	overflow: hidden;
`

const DialogActions = styled(DialogActionsBase)`
	padding: 0;
	margin-top: 25px;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	gap: 10px;

	${respondAbove.sm`
		flex-direction: row;
		
		& button {
			max-width: 210px;
		}
	`}
`

const ImgWrapper = styled.div`
	display: flex;
	justify-content: center;
	max-width: 193px;
	max-height: 145px;
	margin: 0 auto;

	img {
		width: 100%;
		height: 100%;
	}
`

const InfoContainer = styled.div`
	display: flex;
	flex-flow: column nowrap;

	${respondAbove.sm`
		padding: 20px;
	`}
`

const CloseButton = styled(IconButton)`
	align-self: end;
	width: 20px;
	height: 20px;
	margin: 0;
	padding: 0;
`

export interface Props {
	showCancelButton?: boolean
	message: string | ReactNode
	okText: string
	cancelText?: string
	title?: string
	okButtonType?: ButtonType
	customButtonType?: ButtonType
	id?: string
	okButtonId?: string
	cancelButtonId?: string
	closeButtonId?: string
	customButtonId?: string
	closable?: boolean
	open?: boolean
	closeNotification?: () => void
	okAction?: () => void
	cancelAction?: () => void
	customAction?: () => void
	onClose?: () => void
	topImg?: React.ReactElement
	style?: object
}

const DialogBox = (props: Props) => {
	const {
		title,
		cancelText,
		topImg,
		open,
		message,
		okText,
		okButtonType,
		id,
		okButtonId,
		cancelButtonId,
		closable = true,
		closeButtonId,
		customButtonId,
		closeNotification,
		customAction,
		cancelAction,
		okAction,
		showCancelButton = true,
		customButtonType = 'primary',
		onClose = cancelAction,
		style = {},
	} = props

	const handleCloseNotification = () => {
		onClose?.()
		return cancelAction ?? closeNotification ?? (() => {})
	}

	return (
		<Dialog
			open={open ?? false}
			onClose={
				closable
					? handleCloseNotification
					: () => {
							console.warn('DialogBox is forcefully not closed')
					  }
			}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			style={{ zIndex: '1301', ...style }}
			id={id}
			PaperProps={{
				'data-testid': 'alert-dialog',
			}}
		>
			{closable && (
				<CloseButton edge="end" color="inherit" aria-label="close" onClick={onClose} id={closeButtonId} data-testid="close-dialog-button">
					<CloseIcon />
				</CloseButton>
			)}
			{topImg && <ImgWrapper>{topImg}</ImgWrapper>}
			<InfoContainer>
				{!!title && (
					<DialogTitle id="alert-dialog-title" disableTypography data-testid="alert-dialog-title">
						{title}
					</DialogTitle>
				)}
				<DialogContent data-testid="alert-dialog-message">{message}</DialogContent>
				<DialogActions disableSpacing>
					{cancelAction && showCancelButton && (
						<Button
							$type="secondary"
							onClick={() => {
								onClose?.()
								cancelAction()
							}}
							id={cancelButtonId}
							data-testid="dialog-cancel-button"
						>
							{cancelText}
						</Button>
					)}
					{okAction && (
						<Button
							$type={okButtonType}
							onClick={() => {
								if (closable) {
									onClose?.()
								}

								okAction()
							}}
							id={okButtonId}
							data-testid="dialog-confirm-button"
						>
							{okText}
						</Button>
					)}
					{!!customAction && (
						<Button $type={customButtonType} onClick={customAction} id={customButtonId} data-testid="dialog-confirm-button">
							{cancelText || okText}
						</Button>
					)}
				</DialogActions>
			</InfoContainer>
		</Dialog>
	)
}

export default DialogBox
