import type { ReactNode } from 'react'
import React, { useContext, useState } from 'react'
import AccordionBase from '@material-ui/core/Accordion'
import AccordionSummaryBase from '@material-ui/core/AccordionSummary'
import AccordionDetailsBase from '@material-ui/core/AccordionDetails'
import styled from 'styled-components/macro'
import IconComponent from 'themes/IconComponent'
import TypographyPro from 'themes/TypographyPro'
import { useStores } from 'hooks/useStores'
import DividerBase from '../Divider'
import { formatPrice, getTranslatedTextByKey } from 'utils/utils'
import { inject, observer } from 'mobx-react'
import { Collapse } from '@material-ui/core'
import CouponItemRow from 'components/common/coupons/CouponItemRow'
import { DiscountInactiveReasonCode, OfferType, type Coupon as CouponType } from 'shared-types/Coupon'
import { sendCustomEvent } from 'utils/analytics/analytics'
import type _Infra from 'mobx/Infra'
import Coupon from './Coupon'
import { StoreContext } from 'contexts/StoreContext'

const ArrowContainer = styled.div`
	background: var(--optionalBackground);
	border-radius: 50%;
	padding: 10px;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
`

const Arrow = styled(IconComponent)`
	width: 12px;
	height: 12px;
`
const Title = styled(TypographyPro)`
	color: var(--strokeMenuTitle);
	text-transform: uppercase;
`

const Divider = styled(DividerBase)`
	margin: 5px 0;
	background-color: var(--optionalBackground);
`

const AccordionDetails = styled(AccordionDetailsBase)`
	flex-direction: column;
	padding: 10px;
`

const AccordionSummary = styled(AccordionSummaryBase)`
	padding: 0 10px;
	height: 50px;

	&.Mui-expanded {
		min-height: 50px;
	}
`

const Accordion = styled(AccordionBase)`
	box-shadow: none;
	border: solid 1px var(--optionalBackground);
`

const LeftIcon = () => (
	<ArrowContainer>
		<Arrow asset="/icons/dropDownArrow.svg" />
	</ArrowContainer>
)

export interface ListItem {
	code: string
	text: ReactNode
	status: 'success' | 'warning'
}

interface CouponsListProps {
	Infra: typeof _Infra
}

const CouponsList = inject('Infra')(
	observer(({ Infra }: CouponsListProps) => {
		const { couponFlowStore, cartStore, couponsStore } = useStores()
		const {
			store: { data: menu },
		} = useContext(StoreContext)
		const { grandTotal, deliveryInfo } = cartStore

		const [expanded, setExpanded] = useState(true)

		const handleUnapply = (coupon: CouponType) => {
			couponFlowStore.unapply(coupon)
			sendCustomEvent({
				category: 'remove_coupon',
				action: 'clicked',
				coupon_code: coupon.code,
				coupon_name: coupon.title[Infra?.locale?.msg],
				price: coupon.price,
				order_type: [...(coupon.orderTypes || [])],
				offer_type: coupon.offerType || '',
				page_path: window.location.pathname,
			})
		}

		return (
			<Collapse in={couponsStore.appliedCoupons.length > 0}>
				<Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
					<AccordionSummary expandIcon={<LeftIcon />} data-testid="accordion-summary-container">
						<Title variant="h4" data-testid="coupons-applied-title">
							{getTranslatedTextByKey('eCommerce.cart.appliedCoupons', 'Applied Coupons')}
						</Title>
					</AccordionSummary>

					<AccordionDetails>
						{couponsStore.appliedCoupons.map((coupon, index) => (
							<React.Fragment key={coupon.code}>
								<Coupon
									status={coupon.flags.active?.value ? 'success' : 'warning'}
									onUnapply={() => handleUnapply(coupon)}
									disabled={cartStore.loading}
								>
									<CouponItemRow
										amount={
											coupon.offerType === OfferType.PERCENTAGE_DISCOUNT
												? `${coupon.price}%`
												: formatPrice(coupon.price, menu?.currency, menu?.countryCode, 1)
										}
										amountLeftToGetDiscount={
											coupon.flags.active?.reasonCode === DiscountInactiveReasonCode.MIN_ORDER_PRICE_NOT_MET &&
											coupon.minOrderPrice
												? formatPrice(
														coupon.minOrderPrice - grandTotal + (deliveryInfo?.charge || 0),
														menu?.currency,
														menu?.countryCode,
														1
												  )
												: ''
										}
										coupon={coupon}
										locale="en_US"
									/>
								</Coupon>
								{index !== couponsStore.appliedCoupons.length - 1 && <Divider />}
							</React.Fragment>
						))}
					</AccordionDetails>
				</Accordion>
			</Collapse>
		)
	})
)

export default CouponsList as unknown as React.FC
