// @ts-nocheck
import React, { useContext } from 'react'
import { inject, observer } from 'mobx-react'
import Typography from '@material-ui/core/Typography'
import { formatPrice } from 'utils/utils'
import { StoreContext } from '../../contexts/StoreContext'
import { isNil } from 'lodash-es'
import TypographyPro from '../../themes/TypographyPro'
import styled from 'styled-components/macro'

const StyledVariation = styled(TypographyPro)`
	line-height: 20px;
	color: var(--inactive);
	
	@media(max-width: 576px) {
		line-height: 18px;
	},
`

const PriceText = styled(TypographyPro)`
	color: var(--inactive);
`

const CartSummaryVariationsChoices = inject(
	'Infra',
	'User'
)(
	observer((props) => {
		const { store } = useContext(StoreContext)
		const rest = store.data

		let indentMultiple = isNil(props.indentMultiple) ? -1 : props.indentMultiple
		indentMultiple++

		// each array element is a Map of addition/s
		return props.variationsChoices.map((_additionMap, _additionMapIdx) => {
			if (_additionMap) {
				return Object.keys(_additionMap).map((_additionKey, _additionIdx) => {
					const addition = _additionMap[_additionKey]
					const additionKey = `${addition.id}_${_additionMapIdx}_${_additionIdx}`

					let _text = addition.name ?? ''
					_text = addition.quantity ? `${addition.quantity} x ${_text}` : _text
					return (
						<React.Fragment key={additionKey}>
							<Typography variant="caption" color="textPrimary">
								<div style={{ display: 'flex', justifyContent: 'space-between' }}>
									<StyledVariation
										component="span"
										variant="BodySmall"
										style={{
											textIndent: `${indentMultiple * 10}px`,
										}}
										data-testid="variation-text"
									>
										{_text}
									</StyledVariation>
									{addition.price > 0 && (
										<span>
											<PriceText variant="BodyRegular" data-testid="variation-price">
												{formatPrice(addition.price, rest.currency, rest.countryCode, addition.quantity)}
											</PriceText>
										</span>
									)}
								</div>
							</Typography>
							{addition.variationsChoices && (
								<CartSummaryVariationsChoices variationsChoices={addition.variationsChoices} indentMultiple={indentMultiple} />
							)}
						</React.Fragment>
					)
				})
			}
		})
	})
)

export default CartSummaryVariationsChoices
