import { formatPrice, getLocaleStr, getTranslatedTextByKey } from 'utils/utils'
import TypographyPro from 'themes/TypographyPro'
import React, { useCallback, useContext } from 'react'
import styled from 'styled-components/macro'
import type { CouponListItem } from 'components/common/coupons/types'
import { DiscountInactiveReasonCode } from 'shared-types/Coupon'
import { StoreContext } from 'contexts/StoreContext'
import { ErrorCode, translate } from 'mobx/Coupons/errors'

const CouponTitle = styled(TypographyPro)`
	color: var(--fonts);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`

const CouponAmount = styled(CouponTitle)`
	color: var(--fonts);
`

type CouponItemRowProps = Omit<CouponListItem, 'code'>

const CouponItemRow = ({ amount, amountLeftToGetDiscount, coupon, locale }: CouponItemRowProps) => {
	const {
		store: { data: menu },
	} = useContext(StoreContext)
	const { active } = coupon.flags
	let content = null
	const generateContent = useCallback(
		({
			activateText,
			couponText,
			component,
		}: { activateText?: boolean; couponText?: boolean; component?: React.FC<React.PropsWithChildren> } = {}) => {
			const Component =
				component ??
				(({ children }) => (
					<TypographyPro data-testid="coupon-name" component="span" variant="BodySmallHighlighted">
						{children}
					</TypographyPro>
				))

			const base = <Component>&quot;{getLocaleStr(coupon.title, locale) || coupon.code}&quot;</Component>

			return (
				<>
					{activateText && `${getTranslatedTextByKey('webviewFlow.coupons.toActive', 'to activate')} `}
					{base}
					{couponText && ` ${getTranslatedTextByKey('webviewFlow.coupons.coupon', 'coupon')}`}
				</>
			)
		},
		[coupon.title, locale]
	)

	if (active?.value) {
		content = (
			<>
				{amount ? <CouponAmount variant="BodySmallHighlighted">{amount}</CouponAmount> : null}
				{generateContent({
					component: ({ children }) => (
						<CouponTitle data-testid="coupon-name" variant="BodySmall">
							{children}
						</CouponTitle>
					),
				})}
			</>
		)
	} else if (active?.reasonCode === DiscountInactiveReasonCode.MIN_ORDER_PRICE_NOT_MET) {
		content = (
			<>
				{getTranslatedTextByKey('webviewFlow.coupons.spendAnother', 'Spend another')} {amountLeftToGetDiscount}{' '}
				{generateContent({ couponText: true, activateText: true })}!
			</>
		)
	} else if (active?.reasonCode === DiscountInactiveReasonCode.MAX_ORDER_PRICE_EXCEEDED) {
		content = (
			<>
				{getTranslatedTextByKey(
					'webviewFlow.coupons.maxAmountDiscountExceeded',
					'This discount applies only to orders under {{amount}}. Please adjust your cart to use',
					{
						amount: formatPrice(coupon.maxOrderPrice, menu?.currency, menu?.countryCode, 1),
					}
				)}{' '}
				{generateContent({ couponText: true })}!
			</>
		)
	} else if (active?.reasonCode === DiscountInactiveReasonCode.MISSING_ITEMS_FROM_SECTION) {
		const missingSectionsIds = coupon.itemOrSectionIds
		const sectionId = missingSectionsIds?.[0]
		const sectionTitle = menu?.sections.find(({ id }) => id === sectionId)?.title
		content = (
			<>
				{generateContent({ activateText: true, couponText: true })}{' '}
				{getTranslatedTextByKey('webviewFlow.coupons.addItemFromSection', 'add an item from section {{title}}', {
					title: sectionTitle ? getLocaleStr(sectionTitle, locale) : '',
				})}
			</>
		)
	} else if (active?.reasonCode === DiscountInactiveReasonCode.MISSING_SPECIFIC_ITEMS) {
		if(coupon.itemOrSectionIds?.length) {
			for(let i = 0; i <= coupon.itemOrSectionIds.length - 1; i++) {
				const itemCouponCode: string = coupon.itemOrSectionIds[i]
				
				const itemId = menu?.codesMap?.[itemCouponCode]
				if(!itemId) {
					continue
				}

				const itemTitle = menu?.items?.[itemId]?.title
				if(itemTitle) {
					content = (
						<>
							{generateContent({ couponText: true, activateText: true })} <br />
							{getTranslatedTextByKey('webviewFlow.coupons.addItemRequired', 'add the "{{title}}" item', {
								title: itemTitle ? getLocaleStr(itemTitle, locale) : '',
							})}
						</>
					)
					break;
				}
			}
		}

		// Temp solution
		// if none of the items linked to the coupon are found on the menu, 
		// we display the message USERS_COUPON_DISCOUNT_INACTIVE in the warning panel
		if(!content) {
			let translation = translate(ErrorCode.USERS_COUPON_DISCOUNT_INACTIVE)
			content = (
				<>
				<TypographyPro component="span" variant="BodySmallHighlighted">
					{coupon.code}{" - "}
				</TypographyPro>
				{getTranslatedTextByKey(translation.key, translation.fallback)}
				</>
			)
		}
	}

	return content
}

export default CouponItemRow
