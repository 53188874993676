import { ORDER_TYPES, CONSTANTS } from 'utils/constants'
import { reverseGeoCode, initSessionAndLoadMenu, getTranslatedTextByKey } from 'utils/utils'
import AddressManager from 'mobx/AddressManager'
import Infra from 'mobx/Infra'
import User from 'mobx/User'
import Home from 'mobx/Home'
import googlePlacesCallbackHandler from 'components/home/ModalContent/googlePlacesCallbackHandler'
import loadMenu from 'utils/api/loadMenu/loadMenu'
import type { NextRouter } from 'next/router'
import type CartStore from 'mobx/Cart'
import type AddressManagerV2Store from 'mobx/AddressManagerV2/store'
import { sendCustomEvent } from './analytics/analytics'

interface LatLng {
	lat: number
	lng: number
}

interface Store {
	id: string
	title: string
}

interface Params {
	wru: string
	c: string
	ref: string
}

type OrderType = typeof ORDER_TYPES[keyof typeof ORDER_TYPES]

export const handleAddressSubmission = async (
	{ id: storeId, title }: Store,
	addressObject: any,
	router: NextRouter,
	setStore: any,
	userOrderType: OrderType,
	cartStore: CartStore
): Promise<void> => {
	if (!storeId) {
		return
	}

	try {
		const updatedAddress = {
			...addressObject,
			title,
		}

		AddressManager.setFullAddress(updatedAddress, userOrderType, false, true)
		AddressManager.setMatchingStoreIdToAddressByOrderType(storeId, userOrderType)

		// We reset delivery fee for peakup
		if (User.getOrderType() === CONSTANTS.DELIVERY_METHODS.PICKUP) {
			const estimatedDeliveryCharge = {
				amount: 0,
				type: ORDER_TYPES.PEAKUP,
			}
			localStorage.setItem('estimatedDeliveryFee', JSON.stringify(estimatedDeliveryCharge))
		}

		await initSessionAndLoadMenu(loadMenu)({ isGeneratedStatically: false, storeId, router, setStore, Home, cartStore })
		AddressManager.setShowLocalizedAddress(true)
		User.setOrderType(userOrderType)
	} catch (error) {
		console.error('Error during submission:', error)
	}
}

export const fetchDeliveryTypeStoresAndOpenPopup = async (
	router: NextRouter,
	setStore: any,
	userOrderType: any,
	cartStore: CartStore,
	addressManagerV2Store: AddressManagerV2Store
): Promise<void> => {
	try {
		Infra.setLoading(true)

		const { l: lang }: { l: string; c: string } = Infra.appParams
		addressManagerV2Store.setAddressPopup({ ...addressManagerV2Store.addressPopup, mapButtonClicked: true })

		const queryParams = `lang=${lang}&type=getBranchesList&cust=openRest`
		const branchesListResponse = await Home.getBranchesList(queryParams)
		Home.setBranchesList(branchesListResponse)

		// Save previous order type, then switch the order type to the one selected on store-locator popup
		const previousOrderType =
			User.getOrderType() === CONSTANTS.DELIVERY_METHODS.DELIVERY ? CONSTANTS.DELIVERY_METHODS.DELIVERY : CONSTANTS.DELIVERY_METHODS.PICKUP
		User.setOrderType(userOrderType)

		await googlePlacesCallbackHandler({}, userOrderType, addressManagerV2Store)

		addressManagerV2Store.setAddressPopup({
			...addressManagerV2Store.addressPopup,
			open: true,
			onSubmit: async (store: Store, geoCodeResult: any, orderType: OrderType) =>
				handleAddressSubmission(store, geoCodeResult, router, setStore, orderType, cartStore),
			afterCloseAddressPopup: () => {
				User.setOrderType(previousOrderType)
			},
		})
	} catch (error) {
		console.error('Error fetching stores and opening popup:', error)
	} finally {
		Infra.setLoading(false)
	}
}

export const onStartOrderClick = async ({
	orderType,
	setIsLoading = () => {},
	latLng,
	store,
	params,
	router,
	setStore,
	cartStore,
	addressManagerV2Store
}: {
	orderType: OrderType
	setIsLoading: (isLoading: boolean) => void
	latLng: LatLng
	store: Store
	params: Params
	router: NextRouter
	setStore: any
	cartStore: CartStore
	addressManagerV2Store: AddressManagerV2Store
}): Promise<void> => {
	try {
		if (orderType === ORDER_TYPES.DELIVERY) {
			await fetchDeliveryTypeStoresAndOpenPopup(router, setStore, orderType, cartStore, addressManagerV2Store)
			return
		}

		const oldSessionId = User.session?._id

		setIsLoading(true)

		let addressObject = await reverseGeoCode(latLng)
		addressObject = {
			...addressObject,
			storeId: store.id,
			title: store.title,
		}

		addressManagerV2Store.setFullAddress(addressObject, orderType, false, false)

		const { newMenuPath, menuRes, storeMetaData, serverSession } = await addressManagerV2Store.makeLocalizedForOrderTypeAndStoreId(
			params.wru,
			params.c,
			orderType,
			store.id,
			params.ref
		)

		await User.setSession(serverSession)
		await User.setOrderType(orderType)

		setStore((currentStore: Store) => ({
			...currentStore,
			data: menuRes,
			metaData: storeMetaData,
		}))

		if (Infra.appParams?.cartRelocationEnabled && oldSessionId) {
			cartStore.setReady(false)
			const { cartModified } = await cartStore.dryCartRelocation(orderType, oldSessionId)
			await cartStore.relocateCart(orderType, oldSessionId)
			if (cartModified) {
				const notifTitle = `${getTranslatedTextByKey('webviewFlow.cartUpdate')}`
				const message = getTranslatedTextByKey('webviewFlow.storeDoesntHaveAllItems')
				Infra.setNotification({
					title: notifTitle,
					message,
					okAction: () => {
						Infra.closeNotification()
					},
				})

				sendCustomEvent({
					category: 'error',
					action: 'notification',
					label: notifTitle,
					message,
				})
			}
		} else {
			await cartStore.resetCart()
		}

		router.push(newMenuPath)
	} catch (error) {
		console.error('Error during start order initialization:', error)
	} finally {
		setIsLoading(false)
	}
}
