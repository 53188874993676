import React from 'react'
import type { LanguageLocale } from 'utils/utils'
import { formatPrice, getLocaleStr, getTranslatedTextByKey } from 'utils/utils'
import styled from 'styled-components'
import TypographyPro from '../../themes/TypographyPro'
import { ORDER_TYPES } from 'utils/constants'

const StyledCostSummary = styled(TypographyPro)`
	color: var(--footerAndDarkBackgrounds);
`

const StyledDiscountOrCharge = styled(TypographyPro).attrs({
	'data-testid': 'discount-or-charge-container',
})`
	display: flex;
	justify-content: space-between;
	margin: 15px 0 0;
	color: var(--footerAndDarkBackgrounds);
`

interface CostSummaryForChatProps {
	isChatApp: boolean
	locale: LanguageLocale
	currency: string
	countryCode: string
	showTotal: boolean
	totalToPayText: string
	tip?: number
	serverGrandTotal: number
	serverCharges: any[]
	serverAddedItemsFromDiscounts: any[]
	numberOfItems: number
}

const CostSummaryForChat = (props: CostSummaryForChatProps) => {
	const {
		isChatApp,
		tip,
		locale,
		currency,
		countryCode,
		showTotal,
		totalToPayText,
		serverGrandTotal,
		serverCharges,
		serverAddedItemsFromDiscounts,
		numberOfItems,
	} = props

	return (
		<StyledCostSummary variant="BodySmall" component="div">
			{serverAddedItemsFromDiscounts?.length > 0 &&
				serverAddedItemsFromDiscounts.map((_discount, _idx) => {
					if (_discount.title) {
						return (
							<StyledDiscountOrCharge key={_idx} variant="BodySmall">
								<span>{getLocaleStr(_discount.title, locale)}</span>
								{numberOfItems !== 0 && <span>{formatPrice(_discount.price, currency, countryCode)}</span>}
							</StyledDiscountOrCharge>
						)
					}
					return null
				})}

			{/* if charge has zeroAmountDiscount:true then it's an added item via a discount which will be displayed
				by the above array so ignore it below */}

			{serverCharges?.length > 0 &&
				serverCharges.map((_charge, _idx) => {
					if (!_charge.zeroAmountDiscount || _charge.freeDelivery) {
						return (
							!(isChatApp && _charge.type === 'delivery') && (
								<StyledDiscountOrCharge key={_idx} variant="BodySmall">
									<span data-testid={`charges-title-${_idx}`}>
										{_charge.type === ORDER_TYPES.DELIVERY
											? getTranslatedTextByKey('deliveryCost', 'Delivery Fee')
											: getLocaleStr(_charge.title!, props.locale)}
									</span>
									{(_charge.type === ORDER_TYPES.DELIVERY || numberOfItems !== 0) && (
										<span data-testid="charges-amount-delivery-fee">
											{formatPrice(_charge.amount, props.currency, props.countryCode)}
										</span>
									)}
								</StyledDiscountOrCharge>
							)
						)
					}
					return null
				})}

			{!!tip && (
				<StyledDiscountOrCharge variant="BodySmall">
					<span>{getTranslatedTextByKey('webviewFlow.orderConfirmation.tip', 'Tip')}</span>
					<span>{formatPrice(tip, currency, countryCode)}</span>
				</StyledDiscountOrCharge>
			)}

			{showTotal && (
				<StyledDiscountOrCharge variant="SubheadHighlighted">
					<span>{totalToPayText}</span>
					<span data-testid="total-price">{formatPrice(serverGrandTotal, currency, countryCode)}</span>
				</StyledDiscountOrCharge>
			)}
		</StyledCostSummary>
	)
}

export default CostSummaryForChat
