import type { ReactElement } from 'react'
import type React from 'react'
import { inject, observer } from 'mobx-react'
import type { FeatureFlagEnum } from 'types/constants.types'
import type _Infra from 'mobx/Infra'

interface FeatureFlagProps {
	Infra?: typeof _Infra
	children: React.ReactElement
	flag: FeatureFlagEnum
	fallback?: ReactElement | null
}

/**
 * Represents a feature flag component that conditionally renders content based on the existence of a feature flag
 * and a set of boolean conditions.
 *
 * @typedef FeatureFlagProps
 * @property children - The content to be rendered if the feature flag exists and conditions are met.
 * @property flag - The name of the feature flag to check.
 * @property fallback - The fallback component to be rendered if the feature flag doesn't exist or conditions are not met.
 */

const FeatureFlag = inject('Infra')(
	observer(({ Infra, children, flag, fallback: FallbackComponent = null }: FeatureFlagProps) =>
		Infra?.hasFeatureFlag(flag) ? children : FallbackComponent
	)
)

export default FeatureFlag
