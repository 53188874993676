import React from 'react'
import TypographyPro from 'themes/TypographyPro'
import styled from 'styled-components/macro'
import type { ListItem as CouponProps } from './CouponList.types'
import { Grid } from '@material-ui/core'
import IconComponent from 'themes/IconComponent'
import IconButtonBase from '@material-ui/core/IconButton'

const TrashCan = (props: any) => <IconComponent asset="/icons/trashCan.svg" {...props} />

const IconButton = styled(IconButtonBase)`
	padding: 0;
`

const IconButtonStyle = styled(IconButton)`
	svg {
		width: 16px;
		height: 16px;
	}
`

const CouponText = styled(TypographyPro)`
	color: var(--snacksbarSuccessFont);
	word-wrap: break-word;
`

const StyledGrid = styled(Grid)`
	text-align: right;
`

const Container = styled.div<{
	$status: string
}>`
	background-color: ${({ $status }) => ($status === 'success' ? 'var(--clear)' : 'var(--warningBg)')};
	border-left: solid 2px;
	border-color: ${({ $status }) => ($status === 'success' ? 'var(--approved)' : '#E5BF30')};
	padding: 10px;
`

const Coupon = ({ children, status, onUnapply, disabled }: CouponProps) => (
	<Container $status={status} data-testid="coupon-container">
		<Grid container direction="row" alignItems="center">
			<Grid item xs={11}>
				<CouponText component="div" variant="BodySmall">
					{children}
				</CouponText>
			</Grid>
			<StyledGrid item xs={1}>
				<IconButtonStyle onClick={onUnapply} disabled={disabled}  data-testid="remove-coupon-button">
					<TrashCan />
				</IconButtonStyle>
			</StyledGrid>
		</Grid>
	</Container>
)

export default Coupon
