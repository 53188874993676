import React, { useState, useRef } from 'react'
import { inject, observer } from 'mobx-react'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'
import { Textfit } from 'react-textfit'
import Grid from '@material-ui/core/Grid'
import TypographyPro from 'themes/TypographyPro'
import Skip from './Skip'
import { respondAbove } from 'styles/mixins'
import TextField from 'components/common/TextField'
import { useFocusOnMobile } from 'hooks/useFocusOnMobile'

import { SubmitStyledButton, SubmitButtonContainer, Form, TitleContainer, StyledGridContainer } from './Steps.styles'
import Recaptcha from './Recaptcha'
import FriendlyCaptcha from './FriendlyCaptcha'
import { getTranslatedTextByKey, isMobileApp } from 'utils/utils'

import type { AxiosError } from 'axios'
import { IdentifierType } from 'mobx/Infra/Infra.type'
import { getTranslations, isValidEmail, validateInput } from './loginHelper'
import type _Account from 'mobx/Account'
import type _Infra from 'mobx/Infra'

const TermsPrivacyAgreeStyledTypographyPro = styled(TypographyPro)`
	color: var(--footerAndDarkBackgrounds);
	text-align: start;

	a {
		margin: 0;
		line-height: 20px;
		text-decoration: underline;
		color: var(--footerAndDarkBackgrounds);
		${({ theme }) => theme.BodyRegularClickable};
	}
`

const SubTitleContainer = styled(TypographyPro).attrs({ variant: 'BodyRegularHighlighted' })`
	color: var(--strokeMenuTitle);
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-top: 8px;
`

const TermsPrivacyAgreeStyledGrid = styled(Grid)`
	margin-top: 12px;
`

const StyledTypographyProButtonText = styled(TypographyPro)`
	height: 100%;
	max-width: 95%;

	${respondAbove.md`
		max-width: unset;
		width: 100%;
	`}
`

const TitleAndSubtitleContainer = styled.div`
	text-align: center;
`

const RecaptchaContainer = styled.div`
	padding-top: 32px;
	display: flex;
	gap: 10px;
`

const FriendlyCaptchaContainer = styled.div`
	width: 100%;
	margin-top: 10px;
	max-width: none;
`

const VerifyIdentifier = inject(
	'Account',
	'Infra'
)(
	observer(({ Account, Infra, onSkipClick }: { Account: typeof _Account; Infra: typeof _Infra; onSkipClick: Function }) => {
		const { sendMeCode, privacyPolicy, termsConditions, defaultCountry, getIdentifier } = Account
		const { loginMethods, loginMode } = Infra
		const identifier = getIdentifier()
		const {
			handleSubmit,
			setError,
			reset,
			control,
			formState: { errors, isDirty, isValid },
		} = useForm({ mode: 'onChange', defaultValues: { phoneOrEmail: identifier?.value || '' } })
		const translations = getTranslations(loginMode)
		const inputRef = useRef<HTMLInputElement>(null)
		useFocusOnMobile(inputRef)
		const title = Account.signupTitle ?? translations.title()

		const recaptchaEnabled = Infra.appParams.features.reCaptcha?.enabled
		const [reCaptchaCompleted, setReCaptchaCompleted] = useState(false)

		const friendlyCaptchaEnabled = Infra.appParams.features.friendlyCaptcha?.enabled
		const [friendlyCaptchaSolution, setFriendlyCaptchaSolution] = useState<undefined | string>(undefined)
		const friendlyCaptchaRef = useRef<{ start: () => void }>()

		const onSubmit = async ({ phoneOrEmail }: { phoneOrEmail: string }) => {
			try {
				Infra.setLoading(true)
				const type = isValidEmail(phoneOrEmail) ? IdentifierType.Email : IdentifierType.Phone
				await sendMeCode(type, phoneOrEmail, friendlyCaptchaSolution)
			} catch (error: unknown) {
				console.error(error)

				// Errors regarding phone verification that come from the server are handled here
				const { code: errorResponseTextKey, message: defaultMessage } =
					(error as AxiosError<{ error: { code: string; message: string } }>).response?.data?.error || {}
				const keyElements = errorResponseTextKey?.split('.')
				setError('phoneOrEmail', {
					type: 'manual',
					message: getTranslatedTextByKey(`eCommerce.signIn[${keyElements?.[0]}][${keyElements?.[1]}]`, defaultMessage),
				})
			} finally {
				Infra.setLoading(false)
			}
		}

		return (
			<StyledGridContainer>
				<TitleAndSubtitleContainer>
					<TitleContainer container direction="row">
						<TypographyPro variant="h3">{title}</TypographyPro>
					</TitleContainer>
					{Account.signupSubTitle && <SubTitleContainer variant="BodyRegular">{Account.signupSubTitle}</SubTitleContainer>}
				</TitleAndSubtitleContainer>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Grid container direction="row">
						<Controller
							name="phoneOrEmail"
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextField
									onChange={(value) => {
										onChange(value)

										// Start Friendly captcha process after typing on the phone number field
										friendlyCaptchaRef.current?.start()
									}}
									inputRef={inputRef}
									value={value}
									autoFocus
									id="sign-page-identifier-input"
									autoComplete="off"
									onResetValue={(val) => reset(val as unknown as { phoneOrEmail: string })}
									error={Object.prototype.hasOwnProperty.call(errors, 'phoneOrEmail')}
									errorMessage={errors.phoneOrEmail?.message}
									label={`${translations.inputLabel()} *`}
									inputProps={{
										'data-testid': 'sign-in-identifier-field',
										'data-is-email-method': `${loginMethods.emailSupported}`,
									}}
								/>
							)}
							rules={{
								required: true,
								validate: (value) => validateInput({ loginMethods, loginMode, defaultCountry, phoneOrEmail: value }),
							}}
						/>
					</Grid>
					<TermsPrivacyAgreeStyledGrid container direction="row">
						<TermsPrivacyAgreeStyledTypographyPro variant="BodyRegular">
							{getTranslatedTextByKey('eCommerce.signIn.agreeText')}{' '}
							<a href={privacyPolicy} target={isMobileApp() ? '_self' : 'privacy'}>
								{getTranslatedTextByKey('webviewFlow.privacyPolicy')}
							</a>{' '}
							{getTranslatedTextByKey('and')}{' '}
							<a href={termsConditions} target={isMobileApp() ? '_self' : 'terms'}>
								{getTranslatedTextByKey('webviewFlow.termsAndConditions')}
							</a>
						</TermsPrivacyAgreeStyledTypographyPro>
					</TermsPrivacyAgreeStyledGrid>

					{friendlyCaptchaEnabled && (
						<FriendlyCaptchaContainer>
							<FriendlyCaptcha
								ref={friendlyCaptchaRef}
								onComplete={(solution: any) => {
									setFriendlyCaptchaSolution(solution)
								}}
							/>
						</FriendlyCaptchaContainer>
					)}

					{recaptchaEnabled === true && (
						<RecaptchaContainer>
							<Recaptcha
								onComplete={() => {
									setReCaptchaCompleted(true)
								}}
								onError={() => {
									setReCaptchaCompleted(false)
								}}
							/>
						</RecaptchaContainer>
					)}

					<SubmitButtonContainer container direction="row">
						<SubmitStyledButton
							$size="big"
							disabled={
								(!isDirty && !identifier?.value) ||
								!isValid ||
								(recaptchaEnabled && !reCaptchaCompleted) ||
								(friendlyCaptchaEnabled && friendlyCaptchaSolution === undefined)
							}
							type="submit"
							id="sign-page-sign-up-button"
							data-testid="account-submit-button"
						>
							<StyledTypographyProButtonText component="div" variant="CTABig">
								<Textfit mode="single" forceSingleModeWidth={false}>
									{getTranslatedTextByKey('eCommerce.signIn.sendMeACode')}
								</Textfit>
							</StyledTypographyProButtonText>
						</SubmitStyledButton>
					</SubmitButtonContainer>
				</Form>

				{Account.signupDisplaySkipSection && (
					<Skip
						caption={getTranslatedTextByKey('eCommerce.signIn.skip')}
						onSkip={onSkipClick}
						or={getTranslatedTextByKey('eCommerce.signIn.or')}
					/>
				)}
			</StyledGridContainer>
		)
	})
)

export default VerifyIdentifier
